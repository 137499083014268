import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import * as Interfaces from '../../interfaces'
import * as Utils from '../../utils/env'
import * as Components from '../../component'
import LayoutStore from '../../stores/Layout/LayoutStore'
import * as lodash from 'lodash'

interface ListSourceProps {

}

interface ListSourceStates {
    listSource: Interfaces.ISource[],
    isOpenModelCreate: boolean,
    source: Interfaces.ISource,
    total: number,
    page: number,
    page_size: number
}

enum EMode {
    CREATE = 0,
    EDIT = 1,
    DELETE = 2
}

export class ListSource extends React.Component<RouteComponentProps<ListSourceProps>, ListSourceStates> {
    constructor(props) {
        super(props);

        this.state = {
            listSource: [],
            isOpenModelCreate: false,
            page: 1,
            page_size: 20,
            total: 0,
            source: {
                id: '',
                name: '',
                code: 0
            }
        }
    }

    private modeModal = null as EMode

    componentDidMount() {
        LayoutStore.setTitle("Danh sách nguồn Ticket")
        this._getListSource()
    }

    private _getListSource = async () => {
        let { page, page_size } = this.state
        // HrvComponents.ShowWait.onStart()
        // let rsp = await Utils.api_call_get_internal(`tfex/allSource?page=${page}&page_size=${page_size}`, '')
        // if (rsp) {
        //     this.setState({
        //         listSource: [...rsp.data],
        //         total: rsp.total
        //     })
        // }

        // HrvComponents.ShowWait.onEnd()
    }

    private renderHeader = () => {
        return <tr>
            <th className='hrv-table-cell--type-boolean'>ID</th>
            <th>Code</th>
            <th>Tên Nguồn</th>
            <th>Thao tác</th>
        </tr>
    }

    private onEdit = (src: Interfaces.ISource) => {
        this.modeModal = EMode.EDIT


        this.setState({
            source: lodash.cloneDeep(src),
            isOpenModelCreate: true
        })
    }

    private onDelete = (src: Interfaces.ISource) => {
        this.modeModal = EMode.DELETE

        this.setState({
            source: lodash.cloneDeep(src),
            isOpenModelCreate: true
        })
    }


    private onConfirmDelete = async () => {
        let { source } = this.state
        // HrvComponents.ShowWait.onStart()
        // let rsp = await Utils.api_call_delete_internal('tfex/deleteSource', '', source)
        // if (rsp && !rsp.error) {
        //     HrvComponents.Notification.success({
        //         message: "Xóa thành công",
        //         duration: 5,
        //         placement: 'topRight',
        //     })
        // }
        // else {

        //     HrvComponents.Notification.error({
        //         message: rsp.message && rsp.message.length > 0 ? rsp.message[0] : "Xóa thất bại",
        //         duration: 5,
        //         placement: 'topRight',
        //     })
        // }

        this.setState({
            isOpenModelCreate: false,
            source: {
                id: '',
                name: '',
                code: 0
            }
        }, () => this._getListSource())
    }

    private onConfirmEdit = async () => {
        let { source } = this.state
        // HrvComponents.ShowWait.onStart()
        // let rsp = await Utils.api_call_put_internal('tfex/updateSource', '', source)
        // if (rsp && !rsp.error) {
        //     HrvComponents.Notification.success({
        //         message: "Chỉnh sửa thành công",
        //         duration: 5,
        //         placement: 'topRight',
        //     })
        // }
        // else {

        //     HrvComponents.Notification.error({
        //         message: rsp.message && rsp.message.length > 0 ? rsp.message[0] : "Chỉnh sửa thất bại",
        //         duration: 5,
        //         placement: 'topRight',
        //     })
        // }

        this.setState({
            isOpenModelCreate: false,
            source: {
                id: '',
                name: '',
                code: 0
            }
        }, () => this._getListSource())
    }

    private rendeTools = (source: Interfaces.ISource) => {
        return <div className='d-flex' style={{ alignItems: 'center' }}>
            {/* <div className='mr-3 pointer'>
                <HrvComponents.Tooltip placement='top' title='Chỉnh sửa'>
                    <span onClick={() => this.onEdit(source)}>
                        <HrvComponents.Icon type='pencil' theme='solid' />
                    </span>
                </HrvComponents.Tooltip>
            </div>
            <div className='mr-3 pointer'>
                <HrvComponents.Tooltip placement='top' title="Xóa">
                    <span onClick={() => this.onDelete(source)}>
                        <HrvComponents.Icon type='trash' theme='solid' />
                    </span>
                </HrvComponents.Tooltip>
            </div> */}
        </div>
    }

    private renderBody = () => {
        let { listSource } = this.state
        return listSource.length > 0 && listSource.map((it, index) => {
            return <tr key={index}>
                <td>{it.id}</td>
                <td>{it.code}</td>
                <td>{it.name}</td>
                <td>
                    {this.rendeTools(it)}
                </td>
            </tr>
        })

    }

    private createSource = () => {
        this.modeModal = EMode.CREATE
        this.setState({
            isOpenModelCreate: true,
            source: {
                code: 0,
                name: ''
            }
        })
    }

    private renderBodyModal = () => {
        let { source } = this.state
        let { modeModal } = this
        return modeModal == EMode.DELETE
            ? "Bạn có chắc chắn muốn xóa?"
            : <div>
                <label>Tên Nguồn</label>
                {/* <HrvComponents.Input value={source.name} onChange={val => {
                    source.name = val
                    this.setState({ source: source })
                }} /> */}
            </div>
    }

    private addSource = async () => {
        let { source, listSource } = this.state

        let idCorresponding = 0
        let poolIdExists = listSource.map(_ => _.code)
        let isKeepGoing = true
        while (isKeepGoing) {
            idCorresponding += 1
            if (!poolIdExists.includes(idCorresponding)) {
                isKeepGoing = false
            }
        }

        source.code = idCorresponding

        let rsp = await Utils.api_call_post_internal('tfex/addSource', '', source)
        // if (rsp && !rsp.error) {
        //     HrvComponents.Notification.success({
        //         message: "Tạo thành công",
        //         duration: 5,
        //         placement: 'topRight',
        //     })
        // }
        // else {

        //     HrvComponents.Notification.error({
        //         message: rsp.message && rsp.message.length > 0 ? rsp.message[0] : "Tạo thất bại",
        //         duration: 5,
        //         placement: 'topRight',
        //     })
        // }

        this.setState({
            isOpenModelCreate: false,
            source: {
                id: '',
                name: '',
                code: 0
            }
        }, () => this._getListSource())
    }

    private renderHeaderModal = () => {
        let obj = {
            [EMode.CREATE]: "Tạo nguồn",
            [EMode.EDIT]: "Chỉnh sửa nguồn",
            [EMode.DELETE]: "Xóa nguồn"
        }

        return obj[this.modeModal] || ""
    }

    private renderFooterModal = () => {
        let { source } = this.state
        let obj = {
            // [EMode.CREATE]: <HrvComponents.Button status='primary' disabled={!source.name} onClick={() => {
            //     this.addSource()
            // }}>Tạo</HrvComponents.Button>,
            // [EMode.EDIT]: <HrvComponents.Button status='primary' disabled={!source.name} onClick={() => {
            //     this.onConfirmEdit()
            // }}>Chỉnh sửa</HrvComponents.Button>,
            // [EMode.DELETE]: <HrvComponents.Button status='danger' onClick={() => {
            //     this.onConfirmDelete()
            // }}>Xóa</HrvComponents.Button>
        }

        return obj[this.modeModal] || null
    }

    private renderModal = () => {
        let { isOpenModelCreate } = this.state
        return <Components.Modal
            size='sm'
            isOpen={isOpenModelCreate}
            afterCloseModal={() => {
                this.modeModal = null
                this.setState({ isOpenModelCreate: false })
            }}
            headerContent={this.renderHeaderModal()}
            bodyContent={this.renderBodyModal()}
            footerDisabledCloseModal={true}
            footerContent={<div style={{ marginLeft: '8px' }}>
                {this.renderFooterModal()}
            </div>}
        />
    }

    private pagingPage = (current, pageSize) => {
        this.setState({
            page: current
        }, () => this._getListSource())
    }

    private pagingPageSize = (current, pageSize) => {
        this.setState({
            page: 1,
            page_size: pageSize
        }, () => this._getListSource())
    }

    render() {
        let { total, page, page_size } = this.state
        return <div style={{ margin: '40px' }}>
            {this.renderModal()}
            <div style={{ margin: '20px', display: 'flex' }}>
                <span style={{ marginLeft: 'auto' }}>
                    {/* <HrvComponents.Button status='primary' onClick={() => this.createSource()}>
                        <span className='mr-3'>{Utils.Icon.permissionGroup_Plus_Circle(16)}</span>Tạo nguồn
                    </HrvComponents.Button> */}
                </span>
            </div>
            {/* <HrvComponents.Table
                renderTableHeader={this.renderHeader()}
                renderTableBody={this.renderBody()}
            />
            <HrvComponents.Pagination total={total}
                current={page}
                pageSize={page_size}
                onChange={(current, pageSize) => {
                    this.pagingPage(current, pageSize)
                }}
                onChangePageSize={(current, pageSize) => {
                    this.pagingPageSize(current, pageSize)
                }} /> */}
        </div>
    }
}