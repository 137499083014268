import React, { Component } from 'react';
import * as H from 'history';
import * as Utils from '../../utils/env'
import config from '../../config'
import axios from 'axios'
import * as Repositories from '../../repositories'
import './index.css'
import LayoutStore from '../../stores/Layout/LayoutStore'
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

interface ILayoutProps {
    routerHistory: H.History
}

interface AuthStates {
    currentUser: any
}

@observer
export class Layout extends React.Component<ILayoutProps, AuthStates> {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        }
    }

    componentDidMount() {

        this.initData()
    }

    private initData = async () => {
        let accessToken = Utils.token

        if (accessToken) {
            // let user = await axios({
            //     method: 'GET',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'Authorization': `Bearer ${accessToken}`,
            //     },
            //     url: config.hraccount_login.url_get_user_info
            // });
            // let path = config.backend_url + 'api/hara-work/User/getUserInfo'
            let path = config.backend_url + 'api/hara-work/User/getUserInfo'
            let rsp = await fetch(path, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AccessToken': accessToken
                }
            });

            let data = await rsp.json()
            if (data)
                this.setState({ currentUser: data.items })
        }
    }

    private onClickViewProfile = () => {
        // window.open(), "_blank")
    }

    private onClickLogout = () => {
        window.location.href = './log-out'
    }

    private renderLogout = () => {
        // return <HrvComponents.Button status='default' onClick={() => this.onClickLogout()}>Đăng Xuất</HrvComponents.Button>
        return ""
    }

    private renderProfile() {
        let { currentUser } = this.state

        return currentUser && <div className='hrv-header-profile'>
            {/* <HrvComponents.Popover content={this.renderLogout()}>
                <div className='hrv-header-profile-dropdown'>
                    <div className='hrv-stack hrv-stack--align-center'>
                        <div className='hrv-stack--item'>
                            <div className='hrv-header-profile-avatar'>
                                <HrvComponents.Avatar size={32} src={''} sizeImage='small' content={currentUser.name} />
                            </div>
                        </div>
                        <div className='hrv-stack--item hrv-stack--item-fill text-truncate hrv-header-profile-info'>
                            <div className='hrv-stack hrv-stack--size-small hrv-stack--align-center'>
                                <div className='hrv-stack--item hrv-stack--item-fill text-truncate'>
                                    <div className='hrv-header-profile-title'>
                                        {currentUser.name}
                                    </div>
                                    <div className='hrv-header-profile-content'>
                                        {currentUser.orgName}
                                    </div>
                                </div>
                                <div className='hrv-stack--item'>
                                    <div className='hrv-header-profile-arrow'>
                                        {Utils.Icon.arrowHeader(24)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HrvComponents.Popover> */}
        </div>
    }

    private renderTitle() {
        return <div className='new-header-title' title={LayoutStore.title}>{LayoutStore.title}</div>
    }

    render() {
        return (
            <div className='ui-body'>
                <div className='ui-wrapper'>
                    <div className='hrv-header-container'>
                        <div className='hrv-header'>
                            <div className='hrv-header-main'>
                                <div className='new-header__wrapper no-breadcrumb'>
                                    {this.renderTitle()}
                                </div>
                            </div>
                            <div className='hrv-header-actions hrv-stack hrv-stack--gutters hrv-stack--gutters--super'>
                                <div className='hrv-stack--item'>
                                    {/* {this.renderNotification()} */}
                                </div>
                                <div className='hrv-stack--item'>
                                    {this.renderProfile()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hrv-menu-container'>
                        <div className='hrv-menu-logo'>
                            <a href={Utils.getEntryPoint()}>
                                <svg width="149" height="32" viewBox="0 0 149 32">
                                    <path d="M23.6012 15.1119L16.7527 16.9783C16.2603 17.1153 15.7399 17.1153 15.2475 16.9783L8.414 15.0517C8.39294 15.0442 8.37035 15.042 8.34825 15.0454C8.32615 15.0487 8.30522 15.0575 8.28734 15.0709C8.26946 15.0843 8.25517 15.102 8.24576 15.1222C8.23634 15.1425 8.23209 15.1648 8.23338 15.1871V22.3593C8.23238 22.437 8.24683 22.5141 8.27589 22.5862C8.30494 22.6583 8.34802 22.7239 8.40263 22.7792C8.45724 22.8345 8.52229 22.8785 8.594 22.9084C8.66572 22.9384 8.74267 22.9538 8.8204 22.9538H23.1948C23.2725 22.9538 23.3495 22.9384 23.4212 22.9084C23.4929 22.8785 23.558 22.8345 23.6126 22.7792C23.6672 22.7239 23.7103 22.6583 23.7393 22.5862C23.7684 22.5141 23.7828 22.437 23.7818 22.3593V15.285C23.7915 15.26 23.7936 15.2327 23.7878 15.2066C23.782 15.1805 23.7686 15.1567 23.7493 15.1381C23.7299 15.1196 23.7056 15.1073 23.6792 15.1026C23.6529 15.0979 23.6257 15.1011 23.6012 15.1119Z" fill="white" />
                                    <path d="M23.1949 12.0414H18.9277C18.9277 11.243 18.6105 10.4773 18.046 9.91272C17.4814 9.34817 16.7157 9.03101 15.9174 9.03101C15.119 9.03101 14.3533 9.34817 13.7887 9.91272C13.2242 10.4773 12.907 11.243 12.907 12.0414H8.80541C8.67247 12.0429 8.54389 12.089 8.4402 12.1722C8.33652 12.2554 8.26372 12.371 8.23344 12.5004V14.2239C8.23266 14.2551 8.24273 14.2857 8.26193 14.3104C8.28114 14.3351 8.3083 14.3524 8.3388 14.3593L15.2551 16.3386C15.7474 16.4756 16.2679 16.4756 16.7603 16.3386L23.6841 14.4496C23.7144 14.4384 23.7402 14.4177 23.7579 14.3906C23.7755 14.3634 23.7839 14.3314 23.7819 14.2991V12.6359C23.7819 12.4795 23.7203 12.3294 23.6104 12.2181C23.5005 12.1068 23.3512 12.0433 23.1949 12.0414ZM14.0509 12.0414C14.0509 11.5424 14.2492 11.0638 14.602 10.711C14.9549 10.3581 15.4334 10.1599 15.9324 10.1599C16.4314 10.1599 16.91 10.3581 17.2628 10.711C17.6156 11.0638 17.8139 11.5424 17.8139 12.0414H14.0509Z" fill="white" />
                                    <circle cx="16" cy="16" r="16" fill="#2979FF" />
                                    <path d="M23.4532 15.1146L16.5667 16.9913C16.0716 17.1291 15.5483 17.1291 15.0532 16.9913L8.18185 15.054C8.16067 15.0465 8.13796 15.0443 8.11573 15.0477C8.09351 15.0511 8.07247 15.0599 8.05449 15.0734C8.0365 15.0869 8.02214 15.1046 8.01267 15.125C8.0032 15.1454 7.99893 15.1678 8.00023 15.1902V22.4021C7.99923 22.4803 8.01375 22.5578 8.04297 22.6303C8.07218 22.7028 8.1155 22.7688 8.17042 22.8244C8.22533 22.88 8.29074 22.9242 8.36285 22.9543C8.43496 22.9845 8.51234 23 8.5905 23H23.0446C23.1227 23 23.2001 22.9845 23.2722 22.9543C23.3443 22.9242 23.4097 22.88 23.4646 22.8244C23.5195 22.7688 23.5629 22.7028 23.5921 22.6303C23.6213 22.5578 23.6358 22.4803 23.6348 22.4021V15.2886C23.6446 15.2635 23.6466 15.2361 23.6408 15.2098C23.635 15.1836 23.6215 15.1596 23.6021 15.141C23.5827 15.1224 23.5581 15.1099 23.5316 15.1052C23.5051 15.1005 23.4779 15.1038 23.4532 15.1146Z" fill="white" />
                                    <path d="M23.0446 12.027H18.7538C18.7538 11.2242 18.4349 10.4543 17.8672 9.8866C17.2995 9.31892 16.5296 9 15.7268 9C14.924 9 14.154 9.31892 13.5863 9.8866C13.0187 10.4543 12.6997 11.2242 12.6997 12.027H8.57542C8.44175 12.0286 8.31245 12.0749 8.2082 12.1586C8.10394 12.2423 8.03074 12.3585 8.00029 12.4887V14.2216C7.9995 14.2531 8.00963 14.2838 8.02894 14.3086C8.04825 14.3335 8.07556 14.3509 8.10623 14.3578L15.0608 16.3481C15.5559 16.4859 16.0792 16.4859 16.5743 16.3481L23.5365 14.4487C23.567 14.4373 23.593 14.4165 23.6107 14.3892C23.6284 14.362 23.6369 14.3297 23.6349 14.2973V12.6249C23.6349 12.4676 23.5729 12.3167 23.4625 12.2048C23.352 12.0929 23.2019 12.029 23.0446 12.027ZM13.85 12.027C13.85 11.5253 14.0493 11.0441 14.4041 10.6893C14.7589 10.3345 15.2401 10.1351 15.7419 10.1351C16.2437 10.1351 16.7249 10.3345 17.0797 10.6893C17.4345 11.0441 17.6338 11.5253 17.6338 12.027H13.85Z" fill="white" />
                                    <path d="M97.6914 12.0078L100.243 20.7495L102.906 12.0078H106.087L102.031 23.6957H98.6873L97.484 20.2378L96.4397 16.3683L95.4023 20.2551L94.1989 23.713H90.8516L86.7747 12.0251H89.9802L92.6359 20.7495L95.1568 12.0078H97.6914Z" fill="#3E5067" />
                                    <path d="M118.225 17.8414C118.225 21.0642 116.022 23.675 112.391 23.675C108.76 23.675 106.582 21.0642 106.582 17.8414C106.582 14.6186 108.805 12.0078 112.37 12.0078C115.936 12.0078 118.225 14.6393 118.225 17.8414ZM109.352 17.8414C109.352 19.5427 110.389 21.1334 112.391 21.1334C114.393 21.1334 115.434 19.5427 115.434 17.8414C115.434 16.1401 114.252 14.5252 112.391 14.5252C110.41 14.5252 109.365 16.1608 109.365 17.8414H109.352Z" fill="#3E5067" />
                                    <path d="M129.933 8V16.8178L133.806 12.0043H137.209V12.1703L132.523 17.6097L137.863 23.4882V23.6992H134.436L129.94 18.5122V23.6992H127.098V8H129.933Z" fill="#3E5067" />
                                    <path d="M146.508 15.0785C146.122 14.7213 145.669 14.4454 145.175 14.2672C144.68 14.0889 144.155 14.012 143.631 14.0411C142.247 14.0411 141.511 14.4629 141.511 15.196C141.511 15.9291 142.202 16.3752 143.679 16.4686C145.889 16.6103 148.655 17.1048 148.655 20.1962C148.655 22.2468 146.981 24 143.655 24C141.815 24 139.975 23.6957 138.278 21.9252L139.695 19.8747C140.518 20.7945 142.406 21.4791 143.703 21.4999C144.789 21.5241 145.802 20.9605 145.802 20.1167C145.802 19.273 145.142 18.986 143.492 18.8926C141.296 18.7266 138.679 17.9244 138.679 15.2825C138.679 12.6406 141.462 11.6516 143.586 11.6516C145.401 11.6516 146.767 11.9974 148.112 13.1835L146.508 15.0785Z" fill="#3E5067" />
                                    <path d="M40.8459 8.01733V13.7403C41.2572 13.1805 41.7994 12.7301 42.4252 12.4287C43.051 12.1273 43.7412 11.9839 44.4353 12.0113C47.6996 12.0113 49.145 14.2278 49.145 17.6097V23.675H46.2991V17.6339C46.2991 15.5349 45.2029 14.6497 43.6849 14.6497C42.0078 14.6497 40.8424 16.0744 40.8424 17.7999V23.6785H38V8.01733H40.8459Z" fill="#3E5067" />
                                    <path d="M62.9527 12.0182H60.3005V13.9616L60.1933 13.7887C60.0989 13.6476 59.9973 13.5114 59.889 13.3806C59.7213 13.1891 59.5322 13.0174 59.3253 12.8688C58.6851 12.4218 57.9435 12.1413 57.1676 12.0528H57.1192C56.9297 12.0321 56.7392 12.0217 56.5486 12.0216C55.7857 12.0096 55.0258 12.1193 54.2975 12.3467C53.3816 12.6935 52.5678 13.2652 51.9309 14.0091C51.2939 14.753 50.8544 15.6451 50.6528 16.6034C50.5878 17.0001 50.5554 17.4014 50.5559 17.8034C50.5546 18.2353 50.5882 18.6666 50.6562 19.0932C50.9054 20.2716 51.5149 21.3433 52.4002 22.1599C53.2856 22.9765 54.403 23.4975 55.5977 23.6508C55.8271 23.6789 56.0581 23.6927 56.2892 23.6923H56.4829C56.8979 23.6824 57.3101 23.622 57.7105 23.5125L57.8246 23.4813C58.6262 23.2721 59.348 22.8306 59.8994 22.2123C59.9996 22.0705 60.0965 21.9287 60.1829 21.78C60.1829 21.78 60.2728 21.6279 60.3005 21.5691V23.675H62.9839V12.0182H62.9527ZM56.8045 21.1749C54.9164 21.1749 53.3984 19.9162 53.3984 17.8034C53.3984 15.6905 54.9164 14.4561 56.8045 14.4561C61.2826 14.4561 61.2826 21.1749 56.8045 21.1749Z" fill="#3E5067" />
                                    <path d="M85.7198 12.0182H83.0676V13.9616L82.9604 13.7887C82.8659 13.6476 82.7644 13.5114 82.6561 13.3806C82.4884 13.1891 82.2992 13.0174 82.0924 12.8688C81.4522 12.4218 80.7106 12.1413 79.9347 12.0528H79.8863C79.6968 12.0321 79.5063 12.0217 79.3157 12.0216C78.5528 12.0096 77.7929 12.1193 77.0646 12.3467C76.1469 12.6951 75.3317 13.269 74.6942 14.0153C74.0566 14.7616 73.6172 15.6565 73.4164 16.6173C73.3544 17.0142 73.3231 17.4154 73.323 17.8172C73.3217 18.2491 73.3553 18.6805 73.4233 19.107C73.6725 20.2854 74.282 21.3571 75.1673 22.1737C76.0527 22.9903 77.1701 23.5113 78.3647 23.6646C78.5942 23.6927 78.8252 23.7066 79.0563 23.7061H79.25C79.6626 23.6957 80.0725 23.6353 80.4706 23.5263L80.5848 23.4952C81.3869 23.2873 82.1091 22.8456 82.6595 22.2261C82.7627 22.0872 82.8585 21.9429 82.9465 21.7939C82.9465 21.7939 83.033 21.6417 83.0641 21.5829V23.6888H85.7475V12.0182H85.7198ZM79.5854 21.1749C77.6974 21.1749 76.1793 19.9162 76.1793 17.8034C76.1793 15.6905 77.6974 14.4561 79.5854 14.4561C84.0635 14.4561 84.0635 21.1749 79.5854 21.1749Z" fill="#3E5067" />
                                    <path d="M71.888 12.0078C70.7227 12.0078 69.5331 12.2153 68.6479 13.6227V12.0078H65.802V23.6957H68.6479V17.6823C68.6479 15.5833 70.0311 14.8018 71.5146 14.8018C71.6598 14.8018 71.7981 14.8018 71.9261 14.8191V12.0078H71.888Z" fill="#3E5067" />
                                    <path d="M125.735 12.0078C124.567 12.0078 123.381 12.2153 122.492 13.6227V12.0078H119.646V23.6957H122.488V17.6823C122.488 15.5833 123.872 14.8018 125.359 14.8018C125.504 14.8018 125.642 14.8018 125.77 14.8191V12.0078H125.735Z" fill="#3E5067" />
                                </svg>
                            </a>
                        </div>
                        <div className='hrv-menu scrollbar-menu'>
                            <ul className='hrv-menu-list'>
                                {listMenu.map((it, index) => {
                                    return <div key={index}>
                                        <li className='hrv-menu-item'>
                                            <NavLink to={it.link} exact={true} activeClassName='open' className='hrv-menu-item-link'>
                                                <div className='hrv-menu-item-icon'>{it.icon}</div>
                                                <span className='hrv-menu-item-text'>{it.name}</span>
                                            </NavLink>
                                        </li>
                                    </div>
                                })}
                            </ul>
                        </div>
                    </div>
                    <main className='ui-main'>
                        {this.props.children}
                    </main>
                </div>
            </div>
        );
    }
}

const listMenu = [
    { name: "Danh sách Nguồn", link: '/list-source', internalLink: true, show: true, icon: Utils.Icon.needApproved(20), badges: null },
    { name: "Danh sách Loại", link: '/list-expect', internalLink: true, show: true, icon: Utils.Icon.myForm(20), badges: null },
    { name: "Danh sách Ghi Chú", link: '/list-note', internalLink: true, show: true, icon: Utils.Icon.myForm(20), badges: null },
    { name: "Danh sách Log", link: '/list-log', internalLink: true, show: true, icon: Utils.Icon.roundAction(24), badges: null },
]

interface IListMenu {
    name: string,
    link: string,
    internalLink: boolean,
    icon: React.ReactNode,
    show: boolean,
    childs: IListMenu[],
    badges: number,
    isSetting: boolean
}